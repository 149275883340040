import React, { useState, useEffect } from "react";
import ProductServiceService from "./ProductService.service";
import ProductTransactionService from "../OtherService/ProductTransactionService";
import BrandServiceService from "../Brand/BrandService.service";
import CategoryServiceService from "../Category/CategoryService.service";
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";


import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';


import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress';

import LinearProgress from '@mui/material/LinearProgress';

import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';



const ProductList = () => {

    useEffect(() => {
        fetchProductList();
        fetchBrandList();
        fetchCategoryList();
    }, []);

    const [categoryId, setCategoryId] = useState(0);
    const [submitLoadingAdd, setSubmitLoadingAdd] = useState(false);
    const [isAddDisabled, setIsAddDisabled] = useState(false);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [validator, setValidator] = useState({
        severity: '',
        message: '',
        isShow: false
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        '& .MuiTextField-root': { m: 1, width: '25ch' },
    };

    const [open, setOpen] = React.useState(false);

    const handleOpen = (id, e) => {
        console.log('e', id);
        fetchShopOrder(id);
        setOpen(true);
    }

    const [orderSupplierModal, setOrderSupplierModal] = useState({
        id: 0,
        user_id: localStorage.getItem('auth_user_id'),
        product_name: '',
        shop_order_quantity: 0,
        stock: 0
    });

    const fetchShopOrder = async (id) => {
        await ProductServiceService.get(id)
            .then(response => {
                setOrderSupplierModal(response.data);
            })
            .catch(e => {
                console.log("error", e)
            });
    }

    const onChangeInputQuantityModal = (e) => {
        e.persist();
        setOrderSupplierModal({
            ...orderSupplierModal,
            user_id: localStorage.getItem('auth_user_id'),
            shop_order_quantity: e.target.value,
        });
    }

    const updateOrderSupplier = () => {
        setSubmitLoading(true);
        ProductTransactionService.create(orderSupplierModal)
            .then(response => {
                if (response.data.code == 200) {
                    setSubmitLoading(false);
                    setOpen(false);
                    window.scrollTo(0, 0);
                    setValidator({
                        severity: 'success',
                        message: 'Successfuly Added!',
                        isShow: true,
                    });
                    fetchProductList();
                } else if (response.data.code == 400) {
                    setSubmitLoading(false);
                    setOpen(false);
                    window.scrollTo(0, 0);
                    setValidator({
                        severity: 'error',
                        message: response.data.message,
                        isShow: true,
                    });
                } else {
                    setSubmitLoading(false);
                    setOpen(false);
                    setValidator({
                        severity: 'error',
                        message: "Unknown Error",
                        isShow: true,
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    const handleClose = () => setOpen(false);

    const [product, setProduct] = useState({
        id: 0,
        category_id: 0,
        category_name: '',
        brand_id: 0,
        brand_name: '',
        product_name: "",
        price: 0,
        stock: 0,
        weight: 0,
        quantity: 0,
        packaging: ''
    })

    const [brandList, setBrandList] = useState([]);
    const [categeryList, setCategoryList] = useState([]);

    const [message, setMessage] = useState(false);

    const [productList, setProductList] = useState([]);

    const onChangeInput = (e) => {
        console.log(e.target.value)
        setCategoryId(e.target.value)
    }


    const fetchProductList = () => {
        ProductServiceService.getAll()
            .then(response => {
                setProductList(response.data);
            })
            .catch(e => {
                console.log("error", e)
            });
    }

    const fetchBrandList = () => {
        BrandServiceService.getAll()
            .then(response => {
                setBrandList(response.data);
            })
            .catch(e => {
                console.log("error", e)
            });
    }

    const fetchCategoryList = () => {
        CategoryServiceService.getAll()
            .then(response => {
                setCategoryList(response.data);
            })
            .catch(e => {
                console.log("error", e)
            });
    }

    const deleteProduct = (id, e) => {

        const index = productList.findIndex(brand => brand.id === id);
        const newProduct = [...productList];
        newProduct.splice(index, 1);

        ProductServiceService.delete(id)
            .then(response => {
                setProductList(newProduct);
            })
            .catch(e => {
                console.log('error', e);
            });
    }

    const fetchProductByCategoryId = () => {
        setSubmitLoadingAdd(true);
        setIsAddDisabled(true);
        ProductServiceService.fetchProductByCategoryId(categoryId)
            .then(response => {
                setSubmitLoadingAdd(false);
                setIsAddDisabled(false);
                setProductList(response.data);
            })
            .catch(e => {
                setSubmitLoadingAdd(false);
                setIsAddDisabled(false);
                console.log("error", e)
            });
    }



    return (
        <div>
            <Form>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl sx={{ m: 0, minWidth: 320, minHeight: 70 }}>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={shopOrderTransaction.shop_id}
                            label="Shop Name"
                            name="category_id"
                            onChange={onChangeInput}
                        >
                            {
                                categeryList.map((category, index) => (
                                    <MenuItem value={category.id}>{category.category_name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>

                <Button
                    variant="contained"
                    disabled={isAddDisabled}
                    onClick={fetchProductByCategoryId}
                >
                    Search
                </Button>
                <br></br>
                <br></br>
                {submitLoadingAdd &&
                    <LinearProgress color="warning" />
                }
            </Form>

            <br></br>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Add Stocks
                    </Typography>
                    {submitLoading &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    }
                    <TextField
                        disabled
                        id="filled-required"
                        label="Product Name"
                        variant="filled"
                        name='product_name'
                        value={orderSupplierModal.product_name}
                    />

                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-amount">Quantity</InputLabel>
                        <Input
                            type='number'
                            id="filled-required"
                            label="Quantity"
                            variant="filled"
                            name='shop_order_quantity'
                            onChange={onChangeInputQuantityModal}
                        />
                    </FormControl>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={updateOrderSupplier}
                            size="large" >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <table class="table table-bordered">
                <thead class="table-dark">
                    <tr class="table-secondary">
                        <th>ID</th>
                        <th>Product</th>
                        <th>Brand</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Quantity / Weight</th>
                        <th>Stock</th>
                        <th>Stock / Per Piece</th>
                        <th>Packaging</th>
                        <th>Stock Warning</th>
                        <th>Status</th>
                        {/* <th>Add Stock</th> */}
                        <th>Transaction</th>
                        <th>Action</th>
                        <th>Action</th>
                    </tr>
                </thead>
                {productList.length == 0 ?
                    (<tr style={{ color: "red" }}>{"No Data Available"}</tr>)
                    :
                    (
                        <tbody>

                            {
                                productList.map((product, index) => (
                                    <tr key={product.id} >
                                        <td>{product.id}</td>
                                        <td>{product.product_name}</td>
                                        <td>{product.brand_name}</td>
                                        <td>{product.category_name}</td>
                                        <td>₱ {product.price}.00</td>
                                        {/* <td>{product.weight}x{product.quantity}kg</td> */}
                                        <td>{product.quantity === 1 ? <p >{product.weight}kg</p>
                                            : <p >{product.quantity}x{Number.isInteger(product.weight / product.quantity) ? (product.weight / product.quantity) : (product.weight / product.quantity).toPrecision(2)}{product.variation}</p>}
                                        </td>
                                        <td>{product.stock}</td>
                                        <td>{product.stock_pc}</td>
                                        <td>{product.packaging}</td>
                                        <td>{product.stock_warning}</td>
                                        <td>{product.disabled === 0 ? <CheckIcon style={{ color: 'green', }} /> : <CloseIcon style={{ color: 'red', }} />}</td>
                                        {/* <td>
                                    <Tooltip title="Update">
                                        <IconButton>
                                            <UpdateIcon color="primary" onClick={(e) => handleOpen(product.id, e)} />
                                        </IconButton>
                                    </Tooltip>
                                </td> */}
                                        <td>
                                            <Link variant="primary" to={"/productTransactionList/" + product.id}   >
                                                <Button variant="contained" >
                                                    View
                                                </Button>
                                            </Link>
                                        </td>

                                        <td>
                                            <Link variant="primary" to={"/editProduct/" + product.id}   >
                                                <Button variant="contained" >
                                                    Update
                                                </Button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Button disabled variant="contained" color="error" onClick={(e) => deleteProduct(product.id, e)} >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )
                                )
                            }
                        </tbody>)}
            </table>



        </div>
    )
}

export default ProductList
