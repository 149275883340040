import React, { useState } from "react";
import { Button, Form, Alert } from 'react-bootstrap';
import PaymentTypePoService from "../OtherService/PaymentTypePoService";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

const AddPoPaymentType = (props) => {

    const paymentTermList = props.paymenTermList;

    const [paymentType, setPaymentType] = useState({
        id: 0,
        payment_type: '',
        payment_type_description: '',
        status: 1,
        type: 0,
        created_at: '',
        updated_at: ''
    });

    const [message, setMessage] = useState(false);

    const onChangePaymentType = (e) => {
        setPaymentType({ ...paymentType, [e.target.name]: e.target.value });
    }

    const handlePaymentTermChange = (e, value) => {
        e.persist();
        console.log(value)

        setPaymentType({
            ...paymentType,
            type: value.id
        });


    }

    const savePaymentType = () => {
        PaymentTypePoService.sanctum().then(response => {
            PaymentTypePoService.create(paymentType)
                .then(response => {
                    props.onSavePaymentTypeData(response.data);
                    setMessage(true);
                    setPaymentType({
                        payment_type: ''
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    return (
        <div>
            {message &&
                <Alert variant="success" dismissible>
                    <Alert.Heading>Successfully Added!</Alert.Heading>
                    <p>
                        Change this and that and try again. Duis mollis, est non commodo
                        luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                        Cras mattis consectetur purus sit amet fermentum.
                    </p>
                </Alert>
            }

            <Form>
                <Box
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                // onSubmit={saveOrderSupplier}
                >
                    {/* {formErrors.payment_term_id && <p style={{ color: "red" }}>{formErrors.payment_term_id}</p>} */}
                    <FormControl variant="standard" >
                        <Autocomplete
                            // {...defaultProps}
                            options={paymentTermList}
                            className="mb-3"
                            id="disable-close-on-select"
                            onChange={handlePaymentTermChange}
                            getOptionLabel={(paymentTermList) => paymentTermList.payment_term}
                            renderInput={(params) => (
                                <TextField {...params} label="Choose Type" variant="standard" />
                            )}
                        />
                    </FormControl>
                    <br></br>
                </Box>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control type="text" value={paymentType.payment_type} name="payment_type" placeholder="Enter Payment Type Name" onChange={onChangePaymentType} />
                    <Form.Text className="text-muted"  >
                        ..
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control type="text" value={paymentType.payment_type_description} name="payment_type_description" placeholder="Enter Account Number" onChange={onChangePaymentType} />
                    <Form.Text className="text-muted"  >
                        ..
                    </Form.Text>
                </Form.Group>

                <Button variant="primary" onClick={savePaymentType}>
                    Submit
                </Button>
            </Form>
            <br></br>

        </div>
    )
}

export default AddPoPaymentType
