import React, { useState, useEffect } from "react";
import ProductServiceService from "./ProductService.service";
import ProductTransactionService from "../OtherService/ProductTransactionService";
import BrandServiceService from "../Brand/BrandService.service";
import CategoryServiceService from "../Category/CategoryService.service";
import { Form, FloatingLabel } from 'react-bootstrap';
import { Link } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress';

import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';


const AddProduct = () => {

  useEffect(() => {
    fetchProductList();
    fetchBrandList();
    fetchCategoryList();
  }, []);


  const [submitLoading, setSubmitLoading] = useState(false);
  const [validator, setValidator] = useState({
    severity: '',
    message: '',
    isShow: false
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '& .MuiTextField-root': { m: 1, width: '25ch' },
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = (id, e) => {
    console.log('e', id);
    fetchShopOrder(id);
    setOpen(true);
  }

  const [orderSupplierModal, setOrderSupplierModal] = useState({
    id: 0,
    user_id: localStorage.getItem('auth_user_id'),
    product_name: '',
    shop_order_quantity: 0,
    stock: 0
  });

  const fetchShopOrder = async (id) => {
    await ProductServiceService.get(id)
      .then(response => {
        setOrderSupplierModal(response.data);
      })
      .catch(e => {
        console.log("error", e)
      });
  }

  const onChangeInputQuantityModal = (e) => {
    e.persist();
    setOrderSupplierModal({
      ...orderSupplierModal,
      user_id: localStorage.getItem('auth_user_id'),
      shop_order_quantity: e.target.value,
    });
  }

  const updateOrderSupplier = () => {
    setSubmitLoading(true);
    ProductTransactionService.create(orderSupplierModal)
      .then(response => {
        if (response.data.code == 200) {
          setSubmitLoading(false);
          setOpen(false);
          window.scrollTo(0, 0);
          setValidator({
            severity: 'success',
            message: 'Successfuly Added!',
            isShow: true,
          });
          fetchProductList();
        } else if (response.data.code == 400) {
          setSubmitLoading(false);
          setOpen(false);
          window.scrollTo(0, 0);
          setValidator({
            severity: 'error',
            message: response.data.message,
            isShow: true,
          });
        } else {
          setSubmitLoading(false);
          setOpen(false);
          setValidator({
            severity: 'error',
            message: "Unknown Error",
            isShow: true,
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleClose = () => setOpen(false);

  const [product, setProduct] = useState({
    id: 0,
    category_id: 0,
    category_name: '',
    brand_id: 0,
    brand_name: '',
    product_name: "",
    price: 0,
    stock: 0,
    weight: 0,
    variation: '',
    quantity: 0,
    stock_warning: 0,
    packaging: ''
  })

  const [brandList, setBrandList] = useState([]);
  const [categeryList, setCategoryList] = useState([]);

  const [message, setMessage] = useState(false);

  const [variationLabel, setVariationLabel] = useState('Weight');

  const [quantityLabel, setQuantityLabel] = useState('');

  const [productList, setProductList] = useState([]);

  const [submitLoadingAdd, setSubmitLoadingAdd] = useState(false);
  const [isAddDisabled, setIsAddDisabled] = useState(false);

  const [formErrors, setFormErrors] = useState({});


  const onChangePackaging = (e) => {
    console.log("ey", e.target.value)
    setProduct({ ...product, packaging: e.target.value });
    setQuantityLabel(e.target.value);

  }

  const onChangeVariation = (e) => {
    setProduct({ ...product, variation: e.target.value });
    if (e.target.value === "kg") {
      setVariationLabel("Weight");
    } else {
      setVariationLabel("Pcs");
    }

  }

  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }

  const validate = (values) => {
    const errors = {};
    console.log("prodcts: ", product);
    if (product.product_name.length == 0) {
      errors.product_name = "Product Name is Required!";
      // console.log("Product Name is Required!");
    }
    if (product.category_id == 0) {
      errors.category_id = "Category is Required!";
      // console.log("Category is Required!");
    }
    if (product.brand_id == 0) {
      errors.brand_id = "Brand is Required!";
      // console.log("Brand is Required!");
    }
    if (product.price == 0) {
      errors.price = "Price is Required!";
      // console.log("Price is Required!");
    }
    if (product.packaging.length == 0) {
      errors.packaging = "Packaging is Required!";
      // console.log("Packaging is Required!");
    }
    if (product.variation.length == 0) {
      errors.variation = "Variation is Required!";
      // console.log("Variation is Required!");
    }
    if (product.weight == 0) {
      errors.weight = "Weight is Required!";
      // console.log("Weight is Required!");
    }
    if (product.quantity == 0) {
      errors.quantity = "Quantity is Required!";
      // console.log("Quantity is Required!");
    }
    if (product.stock == 0) {
      errors.stock = "Stock is Required!";
      // console.log("Stock is Required!");
    }
    if (product.stock_warning == 0) {
      errors.stock_warning = "Stock Warning is Required!";
      // console.log("Stock Warning is Required!");
    }


    return errors;
  }



  const saveProduct = (event) => {
    event.preventDefault();
    console.log("count: ", Object.keys(validate(product)).length);
    console.log("validate: ", validate(product));
    setFormErrors(validate(product));
    if (Object.keys(validate(product)).length > 0) {
      console.log("Has Validation: ");

    } else {
      console.log("Ready for saving: ");
      setSubmitLoadingAdd(true);
      setIsAddDisabled(true);
      ProductServiceService.sanctum().then(response => {
        ProductServiceService.create(product)
          .then(response => {
            if (response.data.code == 200) {
              setSubmitLoading(false);
              setOpen(false);
              window.scrollTo(0, 0);
              setValidator({
                severity: 'success',
                message: 'Successfuly Added!',
                isShow: true,
              });
              fetchProductList();
              setSubmitLoadingAdd(false);
              setIsAddDisabled(false);
            } else if (response.data.code == 400) {
              setSubmitLoading(false);
              setOpen(false);
              window.scrollTo(0, 0);
              setValidator({
                severity: 'error',
                message: response.data.message,
                isShow: true,
              });
              setSubmitLoadingAdd(false);
              setIsAddDisabled(false);
            } else {
              setSubmitLoading(false);
              setOpen(false);
              setValidator({
                severity: 'error',
                message: "Unknown Error",
                isShow: true,
              });
              setSubmitLoadingAdd(false);
              setIsAddDisabled(false);
            }
          })
          .catch(e => {
            console.log(e);
          });
      });
    }
  }

  const fetchProductList = () => {
    ProductServiceService.fetchProductByCategoryIdV2()
      .then(response => {
        setProductList(response.data);
      })
      .catch(e => {
        console.log("error", e)
      });
  }

  const fetchBrandList = () => {
    BrandServiceService.getAll()
      .then(response => {
        setBrandList(response.data);
      })
      .catch(e => {
        console.log("error", e)
      });
  }

  const fetchCategoryList = () => {
    CategoryServiceService.getAll()
      .then(response => {
        setCategoryList(response.data);
      })
      .catch(e => {
        console.log("error", e)
      });
  }

  const deleteProduct = (id, e) => {

    const index = productList.findIndex(brand => brand.id === id);
    const newProduct = [...productList];
    newProduct.splice(index, 1);

    ProductServiceService.delete(id)
      .then(response => {
        setProductList(newProduct);
      })
      .catch(e => {
        console.log('error', e);
      });
  }



  return (
    <div>
      <Form onSubmit={saveProduct}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          {validator.isShow &&
            <Alert variant="filled" severity={validator.severity}>{validator.message}</Alert>
          }
        </Stack>
        <br></br>
        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Product</Form.Label>
          <Form.Control type="text" value={product.product_name} name="product_name" placeholder="Enter product" onChange={onChangeProduct} />
        </Form.Group> */}
        {formErrors.product_name && <p style={{ color: "red" }}>{formErrors.product_name}</p>}
        <FloatingLabel
          controlId="floatingInput"
          label="Product Name"
          className="mb-3"

        >
          <Form.Control type="text" value={product.product_name} name="product_name" onChange={onChange} />
        </FloatingLabel>

        {formErrors.category_id && <p style={{ color: "red" }}>{formErrors.category_id}</p>}
        <Form.Select aria-label="Default select example" className="mb-3" name="category_id" onChange={onChange} >

          <option>Select Cateogory</option>
          {
            categeryList.map((category, index) => (
              <option value={category.id}>{category.category_name}</option>
            ))
          }
        </Form.Select>

        {formErrors.brand_id && <p style={{ color: "red" }}>{formErrors.brand_id}</p>}
        <Form.Select aria-label="Default select example" className="mb-3" name="brand_id" onChange={onChange} >
          <option>Select Brand</option>
          {
            brandList.map((brand, index) => (
              <option value={brand.id}>{brand.brand_name}</option>
            ))
          }
        </Form.Select>

        {formErrors.price && <p style={{ color: "red" }}>{formErrors.price}</p>}
        <FloatingLabel
          controlId="floatingInput"
          label="Price"
          className="mb-3"
        >
          <Form.Control type="number" value={product.price} name="price" onChange={onChange} />
        </FloatingLabel>

        {formErrors.packaging && <p style={{ color: "red" }}>{formErrors.packaging}</p>}
        <Form.Select aria-label="Default select example" className="mb-3" onChange={onChangePackaging}  >
          <option>Select Packaging</option>

          <option value="Sack">Sack</option>
          <option value="Box">Box</option>
          <option value="Plastic">Plastic</option>
          <option value="Galloon">Galloon</option>

        </Form.Select>

        {formErrors.variation && <p style={{ color: "red" }}>{formErrors.variation}</p>}
        <Form.Select aria-label="Default select example" className="mb-3" onChange={onChangeVariation}  >
          <option>Select Variation</option>
          <option value="kg">kg</option>
          <option value="pcs">pcs</option>

        </Form.Select>

        {formErrors.weight && <p style={{ color: "red" }}>{formErrors.weight}</p>}
        <FloatingLabel
          controlId="floatingInput"
          label={variationLabel}
          className="mb-3"

        >
          <Form.Control type="text" value={product.weight} name="weight" onChange={onChange} />
        </FloatingLabel>

        {formErrors.quantity && <p style={{ color: "red" }}>{formErrors.quantity}</p>}
        <FloatingLabel
          controlId="floatingInput"
          label={quantityLabel === '' || quantityLabel === 'Select Packaging' ? "Quantity" : "Quantity per " + quantityLabel}
          className="mb-3"
        >

          <Form.Control type="text" value={product.quantity} name="quantity" onChange={onChange} />
        </FloatingLabel>

        {formErrors.stock && <p style={{ color: "red" }}>{formErrors.stock}</p>}
        <FloatingLabel
          controlId="floatingInput"
          label="Stock"
          className="mb-3"
        >
          <Form.Control type="number" value={product.stock} name="stock" onChange={onChange} />
        </FloatingLabel>

        {formErrors.stock_warning && <p style={{ color: "red" }}>{formErrors.stock_warning}</p>}
        <FloatingLabel
          controlId="floatingInput"
          label="Stock Warning"
          className="mb-3"
        >

          <Form.Control type="text" value={product.stock_warning} name="stock_warning" onChange={onChange} />
        </FloatingLabel>


        <Button
          variant="contained"
          type="submit"
          disabled={isAddDisabled}
        >
          Submit
        </Button>
        <br></br>
        <br></br>
        {submitLoadingAdd &&
          <LinearProgress color="warning" />
        }
        <br></br>
      </Form>
      <br></br>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Add Stocks
          </Typography>
          {submitLoading &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          }
          <TextField
            disabled
            id="filled-"
            label="Product Name"
            variant="filled"
            name='product_name'
            value={orderSupplierModal.product_name}
          />

          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Quantity</InputLabel>
            <Input
              type='number'
              id="filled-"
              label="Quantity"
              variant="filled"
              name='shop_order_quantity'
              onChange={onChangeInputQuantityModal}
            />
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={updateOrderSupplier}
              size="large" >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      <table class="table table-bordered">
        <thead class="table-dark">
          <tr class="table-secondary">
            <th>ID</th>
            <th>Product</th>
            <th>Brand</th>
            <th>Category</th>
            <th>Price</th>
            <th>Quantity / Weight</th>
            <th>Stock</th>
            <th>Packaging</th>
            <th>Stock / Per Piece</th>
            {/* <th>Add Stock</th> */}
            <th>Transaction</th>
            <th>Action</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          {
            productList.map((product, index) => (
              <tr key={product.id} >
                <td>{product.id}</td>
                <td>{product.product_name}</td>
                <td>{product.brand_name}</td>
                <td>{product.category_name}</td>
                <td>₱ {product.price}.00</td>
                {/* <td>{product.weight}x{product.quantity}kg</td> */}
                {/* <td>{product.quantity === 1 ? <p >{product.weight}{product.variation}</p>
                  : <p >({product.weight / product.quantity}{product.variation}) x {product.quantity}</p>}
                </td> */}
                <td>{product.quantity === 1 ? <p >{product.weight}kg</p>
                  : <p >{product.quantity}x{Number.isInteger(product.weight / product.quantity) ? (product.weight / product.quantity) : (product.weight / product.quantity).toPrecision(2)}{product.variation}</p>}
                </td>
                <td>{product.stock}</td>
                <td>{product.packaging}</td>
                <td>{product.stock_pc}</td>
                {/* <td>
                  <Tooltip title="Update">
                    <IconButton>
                      <UpdateIcon color="primary" onClick={(e) => handleOpen(product.id, e)} />
                    </IconButton>
                  </Tooltip>
                </td> */}
                <td>
                  <Link variant="primary" to={"/productTransactionList/" + product.id}   >
                    <Button variant="contained" >
                      View
                    </Button>
                  </Link>
                </td>

                <td>
                  <Link variant="primary" to={"/editProduct/" + product.id}   >
                    <Button variant="contained" >
                      Update
                    </Button>
                  </Link>
                </td>
                <td>
                  <Button disabled variant="contained" color="error" onClick={(e) => deleteProduct(product.id, e)} >
                    Delete
                  </Button>
                </td>
              </tr>
            )
            )
          }
        </tbody>
      </table>
    </div >
  )
}

export default AddProduct
